import React from "react";
import { Image, Dimensions } from "react-native";

export default function Picture(props) {
  const { width, height } = Dimensions.get("window");
  return (
    <img
      src={props.source}
      style={props.style}
      onContextMenu={(e) => e.preventDefault()}

      //   resizeMode="contain"
    />
  );
}
