import React, {useEffect} from "react";
import '../components/Firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import {useParams} from "react-router-dom"
import Preview from "./Preview";
import { useSearchParams } from 'react-router-dom';


export default function Direct() {

    const routeParams = useParams();
    const [searchParams] = useSearchParams();
    const param1 = searchParams.get('q')

    const fetchUserData = () => {

        firebase.database()
        .ref('users/card_details/' + routeParams.id) 
        .once('value')
        .then((snapshot) => {
            // Data found based on the email field
            const userData = snapshot.val();
            window.location.replace(userData.link + `?q=${param1}`)
            
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });
    };

    useEffect(() => {
        fetchUserData()
    }, [])
    
}