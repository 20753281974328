import React, {useState } from "react";
import {
  View,
  useColorScheme,
  StyleSheet,
  Text,
  StatusBar,
  Dimensions,
  ImageBackground,
  Image,
  ScrollView,
  TouchableOpacity,
  Linking,
  SafeAreaView
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";
// import Entypo from "react-native-web-vector-icons/Entypo"

import Button from "../components/button";
import Picture from "../components/picture";
import '../components/Firebase'
import firebase from 'firebase/app'
import {useParams} from "react-router-dom"
import 'firebase/database'
// import LinearGradient from 'react-native-linear-gradient';
// import AsyncStorage from "@react-native-async-storage/async-storage";
// import database from "@react-native-firebase/database";

const {width, height} = Dimensions.get('window')


function Preview({route, navigation}) {
  const theme = useColorScheme();
  const isDarkTheme = theme === "dark";
  const routeParams = useParams();

  const openLink = (url) => {
    Linking.openURL(url).catch((err) => console.error('Error opening link:', err));
  };

  const openMessage = (PhoneNumber) => {
      openLink(`sms:${PhoneNumber}`);    
  };

  const [color, setColor] = useState('')
  const [name, setName] = useState('')
  const [address, setAddress] = useState([])
  const [company, setComp] = useState('')
  const [job, setJob] = useState('')
  const [bio, setBio] = useState('')
  const [cropped, setCroppedImage] = useState("")
  const [number, setNumber] = useState([])
  const [email, setEmail] = useState([]);
  const [contactCard, setContactCard] = useState([]);
  const [whatsapp, setWhatsapp] = useState([]);
  const [facetime, setFacetime] = useState([]);
  const [call, setCall] = useState([]);
  const [wechat, setWechat] = useState([]);
  const [instagram, setInstagram] = useState([]);
  const [linkedin, setLinkedin] = useState([]);
  const [facebook, setFacebook] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [tiktok, setTiktok] = useState([]);
  const [twitter, setTwitter] = useState([]);
  const [snapchat, setSnapchat] = useState([]);
  const [clubhouse, setClubhouse] = useState([]);
  const [twitch, setTwitch] = useState([]);
  const [pinterest, setPinterest] = useState([]);
  const [website, setWebsite] = useState([]);
  const [calendly, setCalendly] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [etsy, setEtsy] = useState([]);
  const [appLink, setAppLink] = useState([]);
  const [chiliPiper, setChiliPiper] = useState([]);
  const [booksy, setBooksy] = useState([]);
  const [square, setSquare] = useState([]);
  const [yelp, setYelp] = useState([]);
  const [embeddedVideo, setEmbeddedVideo] = useState([]);
  const [zillow, setZillow] = useState([]);
  const [cash, setCash] = useState([]);
  const [venmo, setVenmo] = useState([]);
  const [zelle, setZelle] = useState([]);
  const [paypal, setPaypal] = useState([]);
  const [popit, setPopit] = useState([]);
  const [customLink, setCustomLink] = useState([]);
  const [linktree, setLinktree] = useState([]);
  const [discord, setDiscord] = useState([]);
  const [telegram, setTelegram] = useState([]);
  const [opensea, setOpensea] = useState([]);
  const [poshmark, setPoshmark] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [hoobe, setHoobe] = useState([]);
  const [applemusic, setAppleMusic] = useState([]);
  const [spotify, setSpotify] = useState([]);
  const [soundcloud, setSoundcloud] = useState([]);
  const [uid, setUid] = useState('')
  

  const fetchUserData = () => {

    firebase.database()
    .ref('users/card_details/' + routeParams.id)
    .once('value')
    .then((snapshot) => {
        // Data found based on the email field
        const userData = snapshot.val();
        window.location.replace(`https://popitapp.nl/connect/${userData.index}/hash/${userData.uid}`)


        
        
    })
    .catch((error) => {
        console.error('Error fetching data:', error);
    });
};

  React.useEffect(() => {
    fetchUserData()
  }, [])

  return (
    <SafeAreaView
      style={styles.container}
    >
      

    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: 'white'
    },
    txt : (color, fontWeight, fontSize) => ({
      color: color,
      fontWeight: fontWeight,
      fontSize: fontSize
    }),
    image1: {
      width: width * 0.21,
      height: height * 0.1,
      borderRadius: 20
    },
    input : (backgroundColor, padding, width, borderRadius, marginBottom, color) => ({
      backgroundColor: backgroundColor,
      padding: padding,
      width: width,
      borderRadius: borderRadius,
      marginBottom: marginBottom, 
      color: color
    }),
    inputView: {
      alignItems: 'center',
      justifyContent: 'center'
    },
    button: (backgroundColor) => ({
      backgroundColor: backgroundColor,
      width: width * 0.2,
      padding: height * 0.02,
      alignItems: 'center',
      marginBottom: height * 0.01,
      borderRadius: 30,
      elevation:4,
      shadowOffset: { width: 0, height: 4 }, // Controls the offset of the shadow
    shadowOpacity: 0.3, // Controls the transparency of the shadow
    shadowColor: 'black', // Color of the shadow
    shadowRadius: 4, // Controls the blur radius of the shadow
    }),
    imageWraper: {
      width: 150,
      height:150,
      borderRadius: 75,
      borderWidth: 4,
      backgroundColor:'white',
      overflow: 'hidden',
      borderColor: 'gray',
      marginTop: height * 0.01,
      top: 130,
 
  },
  image: {
      width: '100%',
      height: '100%',
      resizeMode: 'cover',

  },
  logo : {
    width: 60,
    height: 60, 
    borderRadius: 75, 
    borderWidth: 2, 
    backgroundColor:'black', 
    overflow: 'hidden', 
    borderColor: 'black', 
    marginTop: height * 0.01, 
    top: 170, 
    left: 70,

  },
  itemwithnoBack :{
    shadowColor: 'rgba(0, 0, 0, 0.8)', // Shadow color
    shadowOpacity: 0.6, // Shadow opacity
    shadowOffset: { width: 2, height: 2 }, // Shadow offset
    shadowRadius: 4, // Shadow radius
    elevation: 4, // Android shadow elevation
    borderRadius: 12,
    marginBottom:"4%",

},

itemContainer: {
    backgroundColor: 'white',
    padding: '0.5%',
    shadowColor: 'rgba(0, 0, 0, 0.6)', // Shadow color
    shadowOpacity: 0.6, // Shadow opacity
    shadowOffset: { width: 2, height: 2 }, // Shadow offset
    shadowRadius: 4, // Shadow radius
    elevation: 4, // Android shadow elevation
    borderRadius: 12,
    marginBottom:"4%"
  },
    
  });

export default Preview;