import React from "react";
import { useParams } from "react-router-dom";
import "firebase/database";
import firebase from "firebase";
import "../components/Firebase";

export default function Integration () {

    const queryParams = useParams();

    const headers = [
        "Name",
        "Company",
        "Job",
        "Note",
        "Locaton",
    ];

    let csvContent = [headers.join(",")];

    const integration = async () => {
        const snapshot = await firebase.database().ref(`users/${queryParams.uid}/connection`).once("value");
        const data = snapshot.val();

        if (data) {
            const promises = data.map(async (item) => {
                const cardSnapshot = await firebase.database().ref(`users/${item.uid}/cards`).once("value");
                const cards = cardSnapshot.val();
                const contact = cards.filter((dat) => dat.index === item.index);

                var quotedBio2 = ''
                if (contact[0].bio.includes('\n')){
                    const quotedBio = `"${contact[0].bio.replace("\n", ' ')}"`;
                    const quotedBio1 = `"${quotedBio.replace(",", ' ')}"`;
                    quotedBio2 = `"${quotedBio1.replace("\xa0", '')}"`;
                }
                else if (contact[0].bio.includes(',')){
                    const quotedBio1 = `"${contact[0].bio.replace(",", ' ')}"`;
                    quotedBio2 = `"${quotedBio1.replace("\xa0", '')}"`;
                }
                else{
                    quotedBio2 = contact[0].bio
                }

                var add = ''

                if (contact[0].address.includes('\n')){
                    const add2 = `${contact[0].address.replace("\n", ' ')}`;
                    const add1 = `${add2.replace(",", ' ')}`;
                    add = `${add1.replace("\xa0", '')}`;
                }
                else if (contact[0].address.includes(',')){
                    const add1 = `${contact[0].address.replace(",", ' ')}`;
                    add = `${add1.replace("\xa0", '')}`;
                }
                else{
                    add = contact[0].address
                }
                

                if (contact.length > 0) {
                    const row = [
                        contact[0].name,
                        contact[0].company,
                        contact[0].job,
                        quotedBio2,
                        add,
                    ];
                    return row.join(",");
                } else {
                    return null;
                }
            });

            const rows = await Promise.all(promises);
            csvContent = csvContent.concat(rows.filter(row => row !== null));

            const prompt = window.confirm("Do you want to download Csv?");
            if (prompt) {
                const blob = new Blob([csvContent.join("\n")], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "data.csv";
                a.click();
                URL.revokeObjectURL(url);
            }
        }
    };

    React.useEffect(() => {
        integration()
    }, [])
    return(
        <div>
            
        </div>
    )
}