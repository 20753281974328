import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import bannerImage from "../images/banner.jpg";
import verify from "../images/Verify.png";
import Picture from "../components/picture";
import User from "../images/user.png";
import "./generate_links.css";
import "firebase/database";
import "../components/Firebase";
import firebase from "firebase/app";
import ReactPlayer from "react-player/youtube";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import VCard from "vcard-creator";
import { useSearchParams } from "react-router-dom";

library.add(faChevronDown, faChevronUp, faUserPlus);

function Preview() {
  const routeParams = useParams();
  // console.log("Route", routeParams.uid, routeParams.index);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [searchParams] = useSearchParams();
  const [pop_rank, setPop] = useState([]);
  const [tap_rank, setRank] = useState([]);
  const [connection, setConnection] = useState([]);
  const param1 = searchParams.get("q");
  var tap1 = 0;
  var pop1 = 0;
  const date = new Date();

  const handleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  useEffect(() => {
    score();
  }, [pop_rank, tap_rank, connection]);

  useEffect(() => {
    pop();
  }, []);

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
}

  const tap = async (item, title, name) => {

    var pop = {
      dates: [],
      counts: []
    }

    var tapped = 0
  
    const taps = item.map((itm) => {
      if(itm.value === title){
        if(itm.tap){
          itm.tap += 1
        }
        else{
          itm.tap = tapped + 1
        }        
      }

      return itm
    })

    tap1 += 1
    const formattedDate = formatDate(date)// Default locale

    firebase
    .database()
    .ref(`/users/${routeParams.uid}/cards`)
    .once('value').then((snapshot) => {
      const data = snapshot.val()
      const cards = data.map((itm) => {
        if(itm.index === routeParams.index){
          itm[name] = taps
        }

        return itm
      })

      firebase
      .database()
      .ref(`/users/${routeParams.uid}`)
      .update({cards: cards})

    })

    firebase
    .database()
    .ref(`/users/${routeParams.uid}`).once('value')
    .then((snapshot) => {
      const data = snapshot.val()
      if(data.tap){
        setRank(data.tap)
        if(data.tap.dates.includes(formattedDate)){
          const index = data.tap.dates.indexOf(formattedDate)
          data.tap.counts[index] = data.tap.counts[index] + 1
        }
        else{
          data.tap.dates.push(formattedDate)
          data.tap.counts.push(tap1);
        }
        
        firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .update({tap: data.tap})
      }
      else{

        pop.dates.push(formattedDate);
        pop.counts.push(tap1);

        firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .update({tap: pop})
      }
    })

  }

  const pop = () => {
    var pop = {
      dates: [],
      counts: []
    }

    pop1 += 1

    const formattedDate = formatDate(date)

    firebase
    .database()
    .ref(`/users/${routeParams.uid}`).once('value')
    .then((snapshot) => {
      const data = snapshot.val()
      if(data.pop){
        setPop(data.pop)
        if(data.pop.dates.includes(formattedDate)){
          const index = data.pop.dates.indexOf(formattedDate)
          data.pop.counts[index] = data.pop.counts[index] + 1
        }
        else{
          data.pop.dates.push(formattedDate)
          data.pop.counts.push(pop1);
        }
        
        firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .update({pop: data.pop})
      }
      else{

        pop.dates.push(formattedDate);
        pop.counts.push(pop1);

        firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .update({pop: pop})
      }
    })
    
  }

  const score = () => {
    var sum_tap = 0
    var sum_pop = 0
    var sum_connections = 0
    var tap_through_rate = 0
    var total_sum = 0

    firebase
      .database()
      .ref(`/users/${routeParams.uid}`)
      .once('value').then((snapshot) => {
        const data = snapshot.val()
        if(data.tap){
          sum_tap = data.tap.counts.reduce((accumulator, currentValue) => {
            return accumulator + currentValue
          },0);
        }
        if(data.pop){
          sum_pop = data.pop.counts.reduce((accumulator, currentValue) => {
            return accumulator + currentValue
          },0);
        }
        sum_connections = data.connection.length
        
        tap_through_rate = sum_tap / sum_pop
        
        total_sum = sum_tap + sum_pop + sum_connections

        firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .update({score_insight: total_sum})


      })
  }

  const truncateTextAfterWords = (text, numWords) => {
    if (text) {
      if (text.length > numWords) {
        const truncatedText = text.slice(0, numWords);
        return truncatedText + "...";
      }
    }

    return text;
  };
  const [loading, setLoading] = useState(false);

  const [cropped, setCroppedImage] = useState("");
  const [color, setColor] = useState("");
  const [name, setName] = useState("");
  const [company, setComp] = useState("");
  const [job, setJob] = useState("");
  const [bio, setBio] = useState("");
  const [add, setAdd] = useState("");
  const [number, setNumber] = useState([]);
  const [address, setAddress] = useState([]);
  const [email, setEmail] = useState([]);
  const [contactCard, setContactCard] = useState([]);
  const [whatsapp, setWhatsapp] = useState([]);
  const [facetime, setFacetime] = useState([]);
  const [call, setCall] = useState([]);
  const [wechat, setWechat] = useState([]);
  const [instagram, setInstagram] = useState([]);
  const [linkedin, setLinkedin] = useState([]);
  const [facebook, setFacebook] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [tiktok, setTiktok] = useState([]);
  const [twitter, setTwitter] = useState([]);
  const [snapchat, setSnapchat] = useState([]);
  const [clubhouse, setClubhouse] = useState([]);
  const [twitch, setTwitch] = useState([]);
  const [pinterest, setPinterest] = useState([]);
  const [website, setWebsite] = useState([]);
  const [calendly, setCalendly] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [etsy, setEtsy] = useState([]);
  const [appLink, setAppLink] = useState([]);
  const [chiliPiper, setChiliPiper] = useState([]);
  const [booksy, setBooksy] = useState([]);
  const [square, setSquare] = useState([]);
  const [yelp, setYelp] = useState([]);
  const [embeddedVideo, setEmbeddedVideo] = useState([]);
  const [zillow, setZillow] = useState([]);
  const [file, setFile] = useState([]);
  const [cash, setCash] = useState([]);
  const [venmo, setVenmo] = useState([]);
  const [zelle, setZelle] = useState([]);
  const [paypal, setPaypal] = useState([]);
  const [customLink, setCustomLink] = useState([]);
  const [linktree, setLinktree] = useState([]);
  const [discord, setDiscord] = useState([]);
  const [telegram, setTelegram] = useState([]);
  const [opensea, setOpensea] = useState([]);
  const [poshmark, setPoshmark] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [hoobe, setHoobe] = useState([]);
  const [applemusic, setAppleMusic] = useState([]);
  const [spotify, setSpotify] = useState([]);
  const [soundcloud, setSoundcloud] = useState([]);
  const [header, setHeader] = useState([]);
  const [expandable, setExpandable] = useState([]);
  const [cover, setCover] = useState("");
  const [logo, setLogo] = useState("");
  const [trustpilot, setTrustpilot] = useState([]);
  const [membership, setMembership] = useState([]);


  const openMessage = (open, phoneNumber) => {
    window.location.replace(`${open}:${phoneNumber}`);
  };

  const [allData, setAllData] = useState([]);
  const imageMappings = {
    "message.png": require("../images/verify.jpg"),
    "message.png": require("../images/message.png"),
    "applemail.png": require("../images/applemail.png"),
    "facetime.png": require("../images/facetime.png"),
    "safari.png": require("../images/safari.png"),
    "whatsapp.png": require("../images/whatsapp.png"),
    "wechat.png": require("../images/wechat.png"),
    "address.png": require("../images/address.png"),
    "calendly.png": require("../images/calendly.png"),
    "facebook_logo.png": require("../images/facebook_logo.png"),
    "LinkedIn_logo_initials.webp": require("../images/LinkedIn_logo_initials.webp"),
    "X.png": require("../images/X.png"),
    "youtube.png": require("../images/youtube.png"),
    "instagram_nobg.png": require("../images/instagram_nobg.png"),
    "iphone_Call-removebg-preview.png": require("../images/applecall.png"),
    "snapchat.png": require("../images/snapchat.png"),
    "clubhouse.png": require("../images/clubhouse.png"),
    "twitch.webp": require("../images/twitch.webp"),
    "tiktok.png": require("../images/tiktok.png"),
    "pinterest.png": require("../images/pinterest.png"),
    "etsy.jpg": require("../images/etsy.jpg"),
    "venmo.webp": require("../images/venmo.webp"),
    "cashapp.png": require("../images/cashapp.png"),
    "appstore.png": require("../images/appstore.png"),
    "chilipiper.png": require("../images/chilipiper.png"),
    "booksy.jpg": require("../images/booksy.jpg"),
    "square.jpg": require("../images/square.jpg"),
    "spotify-removebg-preview.png": require("../images/spotify-removebg-preview.png"),
    "discord.png": require("../images/discord.png"),
    "customlink.png": require("../images/customlink.png"),
    "linktree.png": require("../images/linktree.png"),
    "applemusic.jpeg": require("../images/applemusic.jpeg"),
    "soundcloud.png": require("../images/soundcloud.png"),
    "hoobe.webp": require("../images/hoobe.webp"),
    "poshmark.webp": require("../images/poshmark.webp"),
    "telegram.png": require("../images/telegram.png"),
    "podcasts.png": require("../images/podcasts.png"),
    "paypal.jpg": require("../images/paypal.jpg"),
    "zelle.jpg": require("../images/zelle.jpg"),
    "zillow.png": require("../images/zillow.png"),
    "yelp.jpg": require("../images/yelp.jpg"),
    "contactcard.jpg": require("../images/contactcard.jpg"),
    "File.png": require("../images/File.png"),
    "google.png": require("../images/google.png"),
    "trustpilot.png": require("../images/trustpilot.png"),
    "opensea.webp": require("../images/opensea.webp"),
  };

  const getImageSource = (imageName) => {
    const imageSource = imageMappings[imageName];
    return imageSource ? imageSource : null;
  };

  const sortData = () => {
    const proLinks = [
      facetime,
      linkedin,
      facebook,
      clubhouse,
      calendly,
      reviews,
      etsy,
      appLink,
      chiliPiper,
      booksy,
      square,
      yelp,
      embeddedVideo,
      file,
      zelle,
      customLink,
      telegram,
      opensea,
      podcasts,
      header,
      trustpilot,
      expandable,
    ];
    var arraysToPush = [
      number,
      email,
      address,
      contactCard,
      whatsapp,
      facetime,
      call,
      wechat,
      instagram,
      linkedin,
      facebook,
      youtube,
      tiktok,
      twitter,
      snapchat,
      clubhouse,
      twitch,
      pinterest,
      website,
      calendly,
      reviews,
      etsy,
      appLink,
      chiliPiper,
      booksy,
      square,
      yelp,
      embeddedVideo,
      zillow,
      file,
      cash,
      venmo,
      zelle,
      paypal,
      customLink,
      linktree,
      discord,
      telegram,
      opensea,
      poshmark,
      podcasts,
      hoobe,
      applemusic,
      spotify,
      soundcloud,
      header,
      expandable,
      trustpilot,
    ];

    let newData = [];

    arraysToPush.forEach(function (array) {
      if (array !== undefined && array !== null && array.length > 0) {
        array.forEach(function (value) {
          if (membership !== "normal") {
            newData.push(value);
          } else if (!proLinks.includes(array)) {
            newData.push(value);
          }
        });
      }
    });

    newData.sort((a, b) => a.sort - b.sort);
    setAllData(newData);
  };

  useEffect(() => {
    sortData();
  }, [cover]);

  const userData = () => {
    if (routeParams.index.includes("-card")) {
      const result = routeParams.index.substring(
        0,
        routeParams.index.indexOf("-card")
      );
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val();
          const card = cards.filter((data) => {
            return data.index === result;
          });

          setName(card[0].name);
          setJob(card[0].job);
          setComp(card[0].company);
          setBio(card[0].bio || "");
          setHeader(card[0].header);
          setCroppedImage(card[0].image);
          setAdd(card[0].address);
          setColor(card[0].color);
          setNumber(card[0].numbers);
          setEmail(card[0].emails);
          setContactCard(card[0].ContactCard);
          setFile(card[0].file);
          setLogo(card[0].logo);
          setWhatsapp(card[0].whatsapp);
          setFacetime(card[0].facetime);
          setHeader(card[0].header);
          setCall(card[0].call);
          setWechat(card[0].wechat);
          setAddress(card[0].addresses);
          setInstagram(card[0].instagram);
          setLinkedin(card[0].linkedin);
          setFacebook(card[0].facebook);
          setYoutube(card[0].youtube);
          setTiktok(card[0].tiktok);
          setTwitter(card[0].twitter);
          setSnapchat(card[0].snapchat);
          setClubhouse(card[0].clubhouse);
          setTwitch(card[0].twitch);
          setPinterest(card[0].pinterest);
          setWebsite(card[0].website);
          setCalendly(card[0].calendly);
          setReviews(card[0].reviews);
          setEtsy(card[0].etsy);
          setAppLink(card[0].applink);
          setChiliPiper(card[0].chilipiper);
          setBooksy(card[0].booksy);
          setSquare(card[0].square);
          setExpandable(card[0].expandable);
          setYelp(card[0].yelp);
          setEmbeddedVideo(card[0].embedded);
          setZillow(card[0].zillow);
          setCash(card[0].cash);
          setVenmo(card[0].venmo);
          setZelle(card[0].zelle);
          setPaypal(card[0].paypal);
          setCustomLink(card[0].customlink);
          setLinktree(card[0].linktree);
          setDiscord(card[0].discord);
          setTelegram(card[0].telegram);
          setOpensea(card[0].opensea);
          setPoshmark(card[0].poshmark);
          setPodcasts(card[0].podcasts);
          setHoobe(card[0].hoobe);
          setAppleMusic(card[0].applemusic);
          setSpotify(card[0].spotify);
          setSoundcloud(card[0].soundcloud);
          setTrustpilot(card[0].trustpilot);

          if (card[0].cover === "") {
            setCover(bannerImage);
          } else {
            setCover(card[0].cover);
          }
        });

        firebase
          .database()
          .ref(`/users/${routeParams.uid}`)
          .once("value")
          .then((snapshot) => {
            const data = snapshot.val();
            setMembership(data.membership)
            setConnection(data.connection);
          });
    } else if (routeParams.index.includes("-keychain")) {
      const result = routeParams.index.substring(
        0,
        routeParams.index.indexOf("-keychain")
      );
      firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          setMembership(data.membership);
          setConnection(data.connection);
        });
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val();
          const card = cards.filter((data) => {
            return data.index === result;
          });

          setName(card[0].name);
          setJob(card[0].job);
          setLogo(card[0].logo);
          setComp(card[0].company);
          setHeader(card[0].header);
          setBio(card[0].bio || "");
          setCroppedImage(card[0].image);
          setColor(card[0].color);
          setNumber(card[0].numbers);
          setEmail(card[0].emails);
          setContactCard(card[0].ContactCard);
          setWhatsapp(card[0].whatsapp);
          setAdd(card[0].address);

          setFile(card[0].file);
          setFacetime(card[0].facetime);
          setCall(card[0].call);
          setWechat(card[0].wechat);
          setAddress(card[0].addresses);
          setInstagram(card[0].instagram);
          setLinkedin(card[0].linkedin);
          setFacebook(card[0].facebook);
          setExpandable(card[0].expandable);
          setYoutube(card[0].youtube);
          setTiktok(card[0].tiktok);
          setTwitter(card[0].twitter);
          setSnapchat(card[0].snapchat);
          setClubhouse(card[0].clubhouse);
          setTwitch(card[0].twitch);
          setPinterest(card[0].pinterest);
          setWebsite(card[0].website);
          setCalendly(card[0].calendly);
          setReviews(card[0].reviews);
          setEtsy(card[0].etsy);
          setAppLink(card[0].applink);
          setChiliPiper(card[0].chilipiper);
          setBooksy(card[0].booksy);
          setSquare(card[0].square);
          setYelp(card[0].yelp);
          setEmbeddedVideo(card[0].embedded);
          setZillow(card[0].zillow);
          setCash(card[0].cash);
          setVenmo(card[0].venmo);
          setZelle(card[0].zelle);
          setPaypal(card[0].paypal);
          setCustomLink(card[0].customlink);
          setLinktree(card[0].linktree);
          setDiscord(card[0].discord);
          setTelegram(card[0].telegram);
          setOpensea(card[0].opensea);
          setPoshmark(card[0].poshmark);
          setPodcasts(card[0].podcasts);
          setHoobe(card[0].hoobe);
          setAppleMusic(card[0].applemusic);
          setSpotify(card[0].spotify);
          setSoundcloud(card[0].soundcloud);
          setTrustpilot(card[0].trustpilot);

          if (card[0].cover === "") {
            setCover(bannerImage);
          } else {
            setCover(card[0].cover);
          }
        });
    } else if (routeParams.index.includes("-button")) {
      const result = routeParams.index.substring(
        0,
        routeParams.index.indexOf("-button")
      );
      firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          setMembership(data.membership);
          setConnection(data.connection);
        });
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val();
          const card = cards.filter((data) => {
            return data.index === result;
          });

          setName(card[0].name);
          setJob(card[0].job);
          setComp(card[0].company);
          setBio(card[0].bio || "");
          setCroppedImage(card[0].image);
          setAdd(card[0].address);

          setColor(card[0].color);
          setLogo(card[0].logo);
          setNumber(card[0].numbers);
          setEmail(card[0].emails);
          setContactCard(card[0].ContactCard);
          setWhatsapp(card[0].whatsapp);
          setFile(card[0].file);
          setFacetime(card[0].facetime);
          setCall(card[0].call);
          setWechat(card[0].wechat);
          setHeader(card[0].header);
          setAddress(card[0].addresses);
          setInstagram(card[0].instagram);
          setExpandable(card[0].expandable);
          setLinkedin(card[0].linkedin);
          setFacebook(card[0].facebook);
          setYoutube(card[0].youtube);
          setTiktok(card[0].tiktok);
          setTwitter(card[0].twitter);
          setSnapchat(card[0].snapchat);
          setClubhouse(card[0].clubhouse);
          setTwitch(card[0].twitch);
          setPinterest(card[0].pinterest);
          setWebsite(card[0].website);
          setCalendly(card[0].calendly);
          setReviews(card[0].reviews);
          setEtsy(card[0].etsy);
          setAppLink(card[0].applink);
          setChiliPiper(card[0].chilipiper);
          setBooksy(card[0].booksy);
          setSquare(card[0].square);
          setYelp(card[0].yelp);
          setEmbeddedVideo(card[0].embedded);
          setZillow(card[0].zillow);
          setCash(card[0].cash);
          setVenmo(card[0].venmo);
          setZelle(card[0].zelle);
          setPaypal(card[0].paypal);
          setCustomLink(card[0].customlink);
          setLinktree(card[0].linktree);
          setDiscord(card[0].discord);
          setTelegram(card[0].telegram);
          setOpensea(card[0].opensea);
          setPoshmark(card[0].poshmark);
          setPodcasts(card[0].podcasts);
          setHoobe(card[0].hoobe);
          setAppleMusic(card[0].applemusic);
          setSpotify(card[0].spotify);
          setSoundcloud(card[0].soundcloud);
          setTrustpilot(card[0].trustpilot);

          if (card[0].cover === "") {
            setCover(bannerImage);
          } else {
            setCover(card[0].cover);
          }
        });
    } else if (routeParams.index.includes("-display")) {
      const result = routeParams.index.substring(
        0,
        routeParams.index.indexOf("-display")
      );
      firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          setMembership(data.membership);
          setConnection(data.connection);
        });
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val();
          const card = cards.filter((data) => {
            return data.index === result;
          });

          setName(card[0].name);
          setJob(card[0].job);
          setComp(card[0].company);
          setBio(card[0].bio || "");
          setCroppedImage(card[0].image);
          setAdd(card[0].address);

          setColor(card[0].color);
          setLogo(card[0].logo);
          setNumber(card[0].numbers);
          setEmail(card[0].emails);
          setContactCard(card[0].ContactCard);
          setWhatsapp(card[0].whatsapp);
          setFile(card[0].file);
          setFacetime(card[0].facetime);
          setCall(card[0].call);
          setWechat(card[0].wechat);
          setHeader(card[0].header);
          setAddress(card[0].addresses);
          setInstagram(card[0].instagram);
          setExpandable(card[0].expandable);
          setLinkedin(card[0].linkedin);
          setFacebook(card[0].facebook);
          setYoutube(card[0].youtube);
          setTiktok(card[0].tiktok);
          setTwitter(card[0].twitter);
          setSnapchat(card[0].snapchat);
          setClubhouse(card[0].clubhouse);
          setTwitch(card[0].twitch);
          setPinterest(card[0].pinterest);
          setWebsite(card[0].website);
          setCalendly(card[0].calendly);
          setReviews(card[0].reviews);
          setEtsy(card[0].etsy);
          setAppLink(card[0].applink);
          setChiliPiper(card[0].chilipiper);
          setBooksy(card[0].booksy);
          setSquare(card[0].square);
          setYelp(card[0].yelp);
          setEmbeddedVideo(card[0].embedded);
          setZillow(card[0].zillow);
          setCash(card[0].cash);
          setVenmo(card[0].venmo);
          setZelle(card[0].zelle);
          setPaypal(card[0].paypal);
          setCustomLink(card[0].customlink);
          setLinktree(card[0].linktree);
          setDiscord(card[0].discord);
          setTelegram(card[0].telegram);
          setOpensea(card[0].opensea);
          setPoshmark(card[0].poshmark);
          setPodcasts(card[0].podcasts);
          setHoobe(card[0].hoobe);
          setAppleMusic(card[0].applemusic);
          setSpotify(card[0].spotify);
          setSoundcloud(card[0].soundcloud);
          setTrustpilot(card[0].trustpilot);

          if (card[0].cover === "") {
            setCover(bannerImage);
          } else {
            setCover(card[0].cover);
          }
        });
    } else {
      firebase
        .database()
        .ref(`/users/${routeParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const data = snapshot.val();
          setMembership(data.membership);
          setConnection(data.connection);
        });
      firebase
        .database()
        .ref(`/users/${routeParams.uid}/cards`)
        .once("value")
        .then((snapshot) => {
          const cards = snapshot.val();
          const card = cards.filter((data) => {
            return data.index === routeParams.index;
          });

          setName(card[0].name);
          setJob(card[0].job);
          setComp(card[0].company);
          setBio(card[0].bio || "");
          setAdd(card[0].address);

          setCroppedImage(card[0].image);
          setColor(card[0].color);
          setNumber(card[0].numbers);
          setEmail(card[0].emails);
          setLogo(card[0].logo);
          setContactCard(card[0].ContactCard);
          setWhatsapp(card[0].whatsapp);
          setFacetime(card[0].facetime);
          setCall(card[0].call);
          setWechat(card[0].wechat);
          setAddress(card[0].addresses);
          setInstagram(card[0].instagram);
          setHeader(card[0].header);
          setLinkedin(card[0].linkedin);
          setFile(card[0].file);
          setFacebook(card[0].facebook);
          setYoutube(card[0].youtube);
          setTiktok(card[0].tiktok);
          setTwitter(card[0].twitter);
          setSnapchat(card[0].snapchat);
          setClubhouse(card[0].clubhouse);
          setTwitch(card[0].twitch);
          setPinterest(card[0].pinterest);
          setWebsite(card[0].website);
          setCalendly(card[0].calendly);
          setReviews(card[0].reviews);
          setEtsy(card[0].etsy);
          setExpandable(card[0].expandable);
          setAppLink(card[0].applink);
          setChiliPiper(card[0].chilipiper);
          setBooksy(card[0].booksy);
          setSquare(card[0].square);
          setYelp(card[0].yelp);
          setEmbeddedVideo(card[0].embedded);
          setZillow(card[0].zillow);
          setCash(card[0].cash);
          setVenmo(card[0].venmo);
          setZelle(card[0].zelle);
          setPaypal(card[0].paypal);
          setCustomLink(card[0].customlink);
          setLinktree(card[0].linktree);
          setDiscord(card[0].discord);
          setTelegram(card[0].telegram);
          setOpensea(card[0].opensea);
          setPoshmark(card[0].poshmark);
          setPodcasts(card[0].podcasts);
          setHoobe(card[0].hoobe);
          setAppleMusic(card[0].applemusic);
          setSpotify(card[0].spotify);
          setSoundcloud(card[0].soundcloud);
          setTrustpilot(card[0].trustpilot);
          if (card[0].cover === "") {
            setCover(bannerImage);
          } else {
            setCover(card[0].cover);
          }
        });
    }
  };

  var contactlinks = [
    "numbers",
    "emails",
    "addresses",
    // "ContactCard",
    "whatsapp",
    "facetime",
    "call",
    "wechat",
    "instagram",
    "linkedin",
    "facebook",
    "youtube",
    "tiktok",
    "twitter",
    "snapchat",
    "clubhouse",
    "twitch",
    "pinterest",
    "website",
    "calendly",
    "reviews",
    "etsy",
    "applink",
    "chilipiper",
    "booksy",
    "square",
    "yelp",
    "embedded",
    "zillow",
    "file",
    "cash",
    "venmo",
    "zelle",
    "paypal",
    "customlink",
    "linktree",
    "discord",
    "telegram",
    "opensea",
    "poshmark",
    "podcasts",
    "hoobe",
    "applemusic",
    "spotify",
    "soundcloud",
    "header",
    "trustpilot"
    // "expandable",
  ];

  const connectMe = async () => {
    try {
      const card = {uid:routeParams.uid, index:routeParams.index}
      var splitcardIndex = [];
      const date1 = new Date();
      const months = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      let day = months[date1.getDay()];
      const date2 = day + ", " + date1.getDate() + "/" + (date1.getMonth() + 1);
      console.log("date: ",date2)
      
      if (routeParams.index.includes("-")) {
        splitcardIndex = routeParams.index.split("-");
        card["index"] = splitcardIndex[0];
      }
      card["date"]=date2
      card["favorite"]="no"
      
      

    
      // Step 1: Read the existing connection data
      const snapshot = await firebase
        .database()
        .ref(`/users/${param1}/connection`)
        .once('value');
  
      // Step 2: Get the current array from the snapshot
      const currentArray = snapshot.val() || []; // Initialize with an empty array if it doesn't exist
  
      // Step 3: Check if the value already exists in the array
      const isValueExists = currentArray.some((item) => {
        return item.uid === card.uid;
      });
      if (!isValueExists) {
        // Step 4: Append the new value to the array
        currentArray.unshift(card);

  
  
        // Step 5: Update the array in the database
        await firebase
          .database()
          .ref(`/users/${param1}/connection`)
          .set(currentArray);
  
        // Step 6: Handle the success or navigate to another screen
        alert('Connected Successfully');
        // navigation.navigate('Contact');
      } else {
        // Handle the case where the value already exists
        alert('Contact Already Exists!');
      }
    } catch (error) {
      // Handle errors
      console.log('Error:', error);
    }
  };

  const save = () => {
    setLoading(true);
    const os = detectOS();
    console.log('OS', os)
    

    if (os === "iOS") {
      console.log("IN ios")
      var vCardData = new VCard();
      

      firebase
        .database()
        .ref(`users/${routeParams.uid}/cards`)
        .once("value")
        .then(async(snapshot) => {
          var splitcardIndex = [];
          const cards = snapshot.val();
          console.log("cards", cards);
          if (routeParams.index.includes("-")) { 
            splitcardIndex = routeParams.index.split("-");
          };

          const contact = cards.filter((data) => {
            if (splitcardIndex.length > 0) {
              return data.index === splitcardIndex[0];
            }
            else {
            return data.index === routeParams.index;
            }
          });
          // console.log("qP:", queryParams.file);
          // console.log("ssss: ", contact[0].image);

          // console.log("ABCCC: ", contact[0].website); 
          // const imageBase64 = contact[0]["image"].toString("base64");

          const res = await axios.get("https://backend.popitapp.nl/image", {
            params: {
              url: contact[0].image,
            },
          });
console.log("res:",res)
const name1 = contact[0].name.includes(" ")
  ? contact[0].name.split(" ")
  : contact[0].name;
const first_name = typeof name1 === "string" ? contact[0].name : name1[0];
const last_name = typeof name1 === "string" ? "" : name1[name1.length - 1];

          vCardData
            .addName(last_name, first_name || "No Name")
            .addCompany(contact[0].company || "No Company")
            .addJobtitle(contact[0].job || "No Job")
            .addRole(contact[0].job || "No Role")
            .addNote(contact[0].bio || "No Bio")
            .addPhoto(res.data.img, res.data.mime);

          for (let j = 0; j < contactlinks.length; j++) {
            if (contact[0][contactlinks[j]] !== undefined) {
              // console.log(contactlinks[j], ":", contact[0][contactlinks[j]]);
              for (let i = 0; i < contact[0][contactlinks[j]].length; i++) {
                if(contact[0][contactlinks[j]][i].check){
                if (
                  contactlinks[j] === "numbers" ||
                  contactlinks[j] === "call"
                ) {
                  vCardData.addPhoneNumber(
                    contact[0][contactlinks[j]][i].value,
                    "Work"
                  );
                } else if (contactlinks[j] === "emails") {
                  vCardData.addEmail(
                    contact[0][contactlinks[j]][i].value,
                    "Email"
                  );
                } else if (contactlinks[j] === "website") {
                  vCardData.addURL(contact[0][contactlinks[j]][i].value);
                } else if (contactlinks[j] === "addresses") {
                  vCardData.addAddress(contact[0][contactlinks[j]][i].value);
                } else if (contactlinks[j] === "file") {
                  console.log("In fff")
                  vCardData.addURL(contact[0][contactlinks[j]][i].value);
                } else if (contactlinks[j] === "applink") {
                  vCardData.addURL(contact[0][contactlinks[j]][i].value);
                } else if (contactlinks[j] === "embedded") {
                  vCardData.addSocial(
                    "https://www.youtube.com/watch?v=" +
                      contact[0][contactlinks[j]][i].value,
                    "youtube",
                    contact[0][contactlinks[j]][i].value
                  );
                } else if (contact[0][contactlinks[j]][i].value !== undefined) {
                  if (
                    contact[0][contactlinks[j]][i].value.startsWith(
                      "https://"
                    ) ||
                    contact[0][contactlinks[j]][i].value.startsWith(
                      "http://"
                    ) ||
                    contact[0][contactlinks[j]][i].value.startsWith(
                      "Https://"
                    ) ||
                    contact[0][contactlinks[j]][i].value.startsWith("Http://")
                  ) {
                    const val = contact[0][contactlinks[j]][i].value.split("/");
                    const value = val[val.length - 1];
                    console.log("IN http");
                    var name = "";
                    if (
                      contact[0][contactlinks[j]][i].value.includes(
                        contactlinks[j]
                      )
                    ) {
                      console.log("IN fileeeee add");
                      name = contactlinks[j];
                      vCardData.addSocial(
                        contact[0][contactlinks[j]][i].value,
                        name,
                        value
                      );
                    } else if (contactlinks[j] === "customlink") {
                      vCardData.addSocial(
                        contact[0][contactlinks[j]][i].value,
                        "custom link",
                        value
                      );
                    }
                  } else if (contact[0][contactlinks[j]][i].name === "Twitch") {
                    vCardData.addSocial(
                      "https://twitch.tv/" +
                        contact[0][contactlinks[j]][i].value,
                      "twitch",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (contact[0][contactlinks[j]][i].name === "Cash") {
                    vCardData.addSocial(
                      "https://cash.app/$" +
                        contact[0][contactlinks[j]][i].value,
                      "cash",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (contact[0][contactlinks[j]][i].name === "Venmo") {
                    vCardData.addSocial(
                      "https://venmo.com/" +
                        contact[0][contactlinks[j]][i].value,
                      "venmo",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "twitter" ||
                    contact[0][contactlinks[j]][i].name === "X" ||
                    contact[0][contactlinks[j]][i].name === "Twitter"
                  ) {
                    vCardData.addSocial(
                      "https://twitter.com/" +
                        contact[0][contactlinks[j]][i].value,
                      "twitter",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Clubhouse"
                  ) {
                    vCardData.addSocial(
                      "https://clubhouse.com/@" +
                        contact[0][contactlinks[j]][i].value,
                      "clubhouse",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Telegram"
                  ) {
                    vCardData.addSocial(
                      "https://t.me/" + contact[0][contactlinks[j]][i].value,
                      "telegram",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Pinterest"
                  ) {
                    vCardData.addSocial(
                      "https://pinterest.com/" +
                        contact[0][contactlinks[j]][i].value,
                      "pinterest",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Instagram"
                  ) {
                    vCardData.addSocial(
                      `https://www.instagram.com/${
                        contact[0][contactlinks[j]][i].value
                      }`,
                      "instagram",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (contact[0][contactlinks[j]][i].name === "Tiktok") {
                    vCardData.addSocial(
                      "https://tiktok.com/@" +
                        contact[0][contactlinks[j]][i].value,
                      "tiktok",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Snapchat"
                  ) {
                    vCardData.addSocial(
                      "https://snapchat.com/add/" +
                        contact[0][contactlinks[j]][i].value,
                      "snapchat",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Whatsapp"
                  ) {
                    vCardData.addSocial(
                      "whatsapp://send?phone=" +
                        contact[0][contactlinks[j]][i].value,
                      "whatsapp",
                      
                    );
                  }
                } else {
                }}
              }
            }
          }

          // Direct VCF DOwnloader Code
          const element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/vcard;charset=utf-8," +
              encodeURIComponent(vCardData.toString())
          );
          if (contact[0].name) {
            element.setAttribute("download", `${contact[0].name}.vcf`);
          } else {
            element.setAttribute("download", "Contact.vcf");
          }
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);

          setLoading(false);
        });
    } else if (os === "Android") {
      setLoading(true);
      console.log("In android")
      var vCardData = new VCard();
      // firebase
      //   .database()
      //   .ref(`users/${routeParams.uid}`)
      //   .once("value")
      //   .then((snapshot) => {
      //     const emaildata = snapshot.val();
      //     console.log("emaildata", emaildata.email)

      //     vCardData.addEmail(
      //       emaildata.email || "Email does not exist",
      //       "Email"
      //     );
      //   });

      firebase
        .database()
        .ref(`users/${routeParams.uid}/cards`)
        .once("value")
        .then(async(snapshot) => {
          var splitcardIndex = [];
          const cards = snapshot.val();
          console.log("cards", cards);
          if (routeParams.index.includes("-")) {
            splitcardIndex = routeParams.index.split("-");
          }

          const contact = cards.filter((data) => {
            if (splitcardIndex.length > 0) {
              return data.index === splitcardIndex[0];
            } else {
              return data.index === routeParams.index;
            }
          });
          // console.log("qP:", routeParams.file);
          // console.log("ssss: ", contact[0].image);

          // console.log("ABCCC: ", contact[0].website);
          // const imageBase64 = contact[0]["image"].toString("base64");
          const res = await axios.get("https://backend.popitapp.nl/image", {
            params: {
              url: contact[0].image,
            },
          });
          console.log("res:", res);
          const name1 = contact[0].name.includes(" ")
            ? contact[0].name.split(" ")
            : contact[0].name;
          const first_name =
            typeof name1 === "string" ? contact[0].name : name1[0];
          const last_name =
            typeof name1 === "string" ? "" : name1[name1.length - 1];
          vCardData
            .addName(last_name, first_name || "No Name")
            .addCompany(contact[0].company || "No Company")
            .addJobtitle(contact[0].job || "No Job")
            .addRole(contact[0].job || "No Role")
            .addNote(contact[0].bio || "No Bio")
            .addPhoto(res.data.img, res.data.mime);

          for (let j = 0; j < contactlinks.length; j++) {
            if (contact[0][contactlinks[j]] !== undefined) {
              // console.log(contactlinks[j], ":", contact[0][contactlinks[j]]);
              for (let i = 0; i < contact[0][contactlinks[j]].length; i++) {
                if(contact[0][contactlinks[j]][i].check){
                if (
                  contactlinks[j] === "numbers" ||
                  contactlinks[j] === "whatsapp" ||
                  contactlinks[j] === "call"
                ) {
                  vCardData.addPhoneNumber(
                    contact[0][contactlinks[j]][i].value,
                    "Work"
                  );
                } else if (contactlinks[j] === "emails") {
                  vCardData.addEmail(
                    contact[0][contactlinks[j]][i].value,
                    "Email"
                  );
                } else if (contactlinks[j] === "file") {
                  vCardData.addURL(contact[0][contactlinks[j]][i].value);
                } else if (contactlinks[j] === "applink") {
                  vCardData.addURL(contact[0][contactlinks[j]][i].value);
                } else if (contactlinks[j] === "website") {
                  vCardData.addURL(contact[0][contactlinks[j]][i].value);
                } else if (contactlinks[j] === "addresses") {
                  vCardData.addAddress(contact[0][contactlinks[j]][i].value);
                } else if (contact[0][contactlinks[j]][i].value !== undefined) {
                  if (
                    contact[0][contactlinks[j]][i].value.startsWith(
                      "https://"
                    ) ||
                    contact[0][contactlinks[j]][i].value.startsWith(
                      "http://"
                    ) ||
                    contact[0][contactlinks[j]][i].value.startsWith(
                      "Https://"
                    ) ||
                    contact[0][contactlinks[j]][i].value.startsWith("Http://")
                  ) {
                    console.log(
                      "URLLLL:",
                      contact[0][contactlinks[j]][i].value
                    );
                    vCardData.addURL(contact[0][contactlinks[j]][i].value);
                  } else if (contact[0][contactlinks[j]][i].name === "Twitch") {
                    vCardData.addURL(
                      "https://twitch.tv/" +
                        contact[0][contactlinks[j]][i].value
                    );
                  } else if (contact[0][contactlinks[j]][i].name === "Cash") {
                    vCardData.addURL(
                      "https://cash.app/$" +
                        contact[0][contactlinks[j]][i].value
                    );
                  } else if (contact[0][contactlinks[j]][i].name === "Venmo") {
                    vCardData.addURL(
                      "https://venmo.com/" +
                        contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "twitter" ||
                    contact[0][contactlinks[j]][i].name === "X" ||
                    contact[0][contactlinks[j]][i].name === "Twitter"
                  ) {
                    vCardData.addURL(
                      "https://twitter.com/" +
                        contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Clubhouse"
                  ) {
                    vCardData.addURL(
                      "https://clubhouse.com/@" +
                        contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Telegram"
                  ) {
                    vCardData.addURL(
                      "https://t.me/" + contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Pinterest"
                  ) {
                    vCardData.addURL(
                      "https://pinterest.com/" +
                        contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Instagram"
                  ) {
                    vCardData.addURL(
                      `https://www.instagram.com/${
                        contact[0][contactlinks[j]][i].value
                      }`
                    );
                  } else if (contact[0][contactlinks[j]][i].name === "Tiktok") {
                    vCardData.addURL(
                      "https://tiktok.com/@" +
                        contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Snapchat"
                  ) {
                    vCardData.addURL(
                      "https://snapchat.com/add/" +
                        contact[0][contactlinks[j]][i].value
                    );
                  }
                } else {
                }}
              }
            }
          }

          // Direct VCF DOwnloader Code
          const element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/vcard;charset=utf-8," +
              encodeURIComponent(vCardData.toString())
          );
          if (contact[0].name) {
            element.setAttribute("download", `${contact[0].name}.vcf`);
          } else {
            element.setAttribute("download", `Contact.vcf`);
          }
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          setLoading(false);
        });
    }
    
  }

  const contact_card = () => {
    const os = detectOS();

    if (os === 'iOS'){
      var vCardData = new VCard();
      firebase
        .database()
        .ref(`users/${routeParams.uid}/cards`)
        .once("value")
        .then(async(snapshot) => {
          const cards = snapshot.val();

          const contact = cards.filter((data) => {
            return data.index === routeParams.index;
          });

          
          const res = await axios.get("https://backend.popitapp.nl/image", {
            params: {
              url: contact[0].image,
            },
          });
          console.log("card:",cards)
          const name1 = contact[0].name.includes(" ")
            ? contact[0].name.split(" ")
            : contact[0].name;
          const first_name = typeof name1 === "string" ? contact[0].name : name1[0];
          const last_name = typeof name1 === "string" ? "" : name1[name1.length - 1];

        
            

          vCardData
            .addName(last_name,first_name || "No Name")
            .addCompany(contact[0].company || "No Company")
            .addJobtitle(contact[0].job || "No Job")
            .addRole(contact[0].job || "No Role")
            .addNote(contact[0].bio || "No Bio")
            .addPhoneNumber(contact[0].number, "PREF;WORK")
            .addPhoto(res.data.img, res.data.mime)

          for (let j = 0; j < contactlinks.length; j++) {
            if (contact[0].ContactCard[0][contactlinks[j]] !== undefined) {
              for (
                let i = 0;
                i < contact[0].ContactCard[0][contactlinks[j]].length;
                i++
              ) {
                if (
                  contact[0].ContactCard[0][contactlinks[j]][i].check === true
                ) {
                  console.log(
                    "Value!!!!",
                    contact[0].ContactCard[0][contactlinks[j]][i].name
                      .charAt(0)
                      .toUpperCase() +
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .slice(1)
                        .toLowerCase()
                  );
                  if (
                    contactlinks[j] === "numbers" ||
                    contactlinks[j] === "whatsapp" ||
                    contactlinks[j] === "call"
                  ) {
                    vCardData.addPhoneNumber(
                      contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "Work"
                    );
                  } else if (contactlinks[j] === "emails") {
                    vCardData.addEmail(
                      contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "Email"
                    );
                  } else if (contactlinks[j] === "website") {
                    vCardData.addURL(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (contactlinks[j] === "addresses") {
                    vCardData.addAddress(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0].ContactCard[0][contactlinks[j]][i].name
                      .charAt(0)
                      .toUpperCase() +
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .slice(1)
                        .toLowerCase() ===
                    "File"
                  ) {
                    // console.log("In fffff")
                    vCardData.addURL(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (contactlinks[j] === "embedded") {
                    vCardData.addSocial(
                      "https://www.youtube.com/watch?v=" +
                        contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "youtube",
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0].ContactCard[0][contactlinks[j]][i].value !==
                    undefined
                  ) {
                    if (
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("https://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("http://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("Https://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("Http://")
                    ) {
                      // console.log("IN http");
                      const val =
                        contact[0].ContactCard[0][contactlinks[j]][
                          i
                        ].value.split("/");
                      const values = val[val.length - 1];
                      var name = "";

                      if (
                        contact[0].ContactCard[0][contactlinks[j]][
                          i
                        ].value.includes(contactlinks[j])
                      ) {
                        name = contactlinks[j];
                        // console.log("name", name, "and", values);
                        vCardData.addSocial(
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                          name,
                          values
                        );
                      }
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Applink"
                    ) {
                      vCardData.addURL(
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Twitch"
                    ) {
                      vCardData.addSocial(
                        "https://twitch.tv/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "twitch",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Cash"
                    ) {
                      vCardData.addSocial(
                        "https://cash.app/$" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "cash",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Venmo"
                    ) {
                      vCardData.addSocial(
                        "https://venmo.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "venmo",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "twitter" ||
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "X" ||
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "Twitter"
                    ) {
                      vCardData.addSocial(
                        "https://twitter.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "twitter",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Clubhouse"
                    ) {
                      vCardData.addSocial(
                        "https://clubhouse.com/@" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "clubhouse",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Telegram"
                    ) {
                      vCardData.addSocial(
                        "https://t.me/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "telegram",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Pinterest"
                    ) {
                      vCardData.addSocial(
                        "https://pinterest.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "pinterest",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Instagram"
                    ) {
                      // console.log("IN instagram!!!");
                      vCardData.addSocial(
                        `https://www.instagram.com/${
                          contact[0].ContactCard[0][contactlinks[j]][i].value
                        }`,
                        "Instagram",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Tiktok"
                    ) {
                      vCardData.addSocial(
                        "https://tiktok.com/@" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "tiktok",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Snapchat"
                    ) {
                      vCardData.addSocial(
                        "https://snapchat.com/add/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "snapchat",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    }
                  }
                }
              }
            }
          }

          // Direct VCF DOwnloader Code
          const element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/vcard;charset=utf-8," +
              encodeURIComponent(vCardData.toString())
          );
          if (contact[0].name) {
            element.setAttribute("download", `${contact[0].name}.vcf`);
          } else {
            element.setAttribute("download", "Contact.vcf");
          }
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
    } else if (os === 'Android') {
      var vCardData = new VCard();
      firebase
        .database()
        .ref(`users/${routeParams.uid}/cards`)
        .once("value")
        .then(async(snapshot) => {
          const cards = snapshot.val();
          // console.log("card: ", cards)

          const contact = cards.filter((data) => {
            return data.index === routeParams.index;
          });

          const res = await axios.get("https://backend.popitapp.nl/image", {
            params: {
              url: contact[0].image,
            },
          });
          // console.log("card:",cards)
          const name1 = contact[0].name.includes(" ")
            ? contact[0].name.split(" ")
            : contact[0].name;
          const first_name = typeof name1 === "string" ? contact[0].name : name1[0];
          const last_name = typeof name1 === "string" ? "" : name1[name1.length - 1];

        
            

          vCardData
            .addName(last_name,first_name || "No Name")
            .addCompany(contact[0].company || "No Company")
            .addJobtitle(contact[0].job || "No Job")
            .addRole(contact[0].job || "No Role")
            .addNote(contact[0].bio || "No Bio")
            .addPhoto(res.data.img, res.data.mime)


          for (let j = 0; j < contactlinks.length; j++) {
            if (contact[0].ContactCard[0][contactlinks[j]] !== undefined) {
              for (
                let i = 0;
                i < contact[0].ContactCard[0][contactlinks[j]].length;
                i++
              ) {
                if (
                  contact[0].ContactCard[0][contactlinks[j]][i].check === true
                ) {
                  if (
                    contactlinks[j] === "numbers" ||
                    contactlinks[j] === "call"
                  ) {
                    vCardData.addPhoneNumber(
                      contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "Work"
                    );
                  } else if (contactlinks[j] === "emails") {
                    vCardData.addEmail(
                      contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "Email"
                    );
                  } else if (
                    contact[0].ContactCard[0][contactlinks[j]][i].name
                      .charAt(0)
                      .toUpperCase() +
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .slice(1)
                        .toLowerCase() ===
                    "File"
                  ) {
                    vCardData.addURL(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0].ContactCard[0][contactlinks[j]][i].name
                      .charAt(0)
                      .toUpperCase() +
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .slice(1)
                        .toLowerCase() ===
                    "Applink"
                  ) {
                    vCardData.addURL(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (contactlinks[j] === "website") {
                    vCardData.addURL(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (contactlinks[j] === "addresses") {
                    vCardData.addAddress(
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (contactlinks[j] === "embedded") {
                    vCardData.addURL(
                      "https://www.youtube.com/watch?v=" +
                        contact[0].ContactCard[0][contactlinks[j]][i].value,
                      "youtube",
                      contact[0].ContactCard[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0].ContactCard[0][contactlinks[j]][i].value !==
                    undefined
                  ) {
                    if (
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("https://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("http://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("Https://") ||
                      contact[0].ContactCard[0][contactlinks[j]][
                        i
                      ].value.startsWith("Http://")
                    ) {
                      const val =
                        contact[0].ContactCard[0][contactlinks[j]][
                          i
                        ].value.split("/");
                      const values = val[val.length - 1];
                      var name = "";
                      if (
                        contact[0].ContactCard[0][contactlinks[j]][
                          i
                        ].value.includes(contactlinks[j])
                      ) {
                        name = contactlinks[j];
                        vCardData.addURL(
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                          name,
                          values
                        );
                      }
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Twitch"
                    ) {
                      vCardData.addURL(
                        "https://twitch.tv/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "twitch",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Cash"
                    ) {
                      vCardData.addURL(
                        "https://cash.app/$" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "cash",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Venmo"
                    ) {
                      vCardData.addURL(
                        "https://venmo.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "venmo",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "twitter" ||
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "X" ||
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                        "Twitter"
                    ) {
                      vCardData.addURL(
                        "https://twitter.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "twitter",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Clubhouse"
                    ) {
                      vCardData.addURL(
                        "https://clubhouse.com/@" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "clubhouse",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Telegram"
                    ) {
                      vCardData.addURL(
                        "https://t.me/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "telegram",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Pinterest"
                    ) {
                      vCardData.addURL(
                        "https://pinterest.com/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "pinterest",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Instagram"
                    ) {
                      vCardData.addURL(
                        `https://www.instagram.com/${
                          contact[0].ContactCard[0][contactlinks[j]][i].value
                        }`,
                        "Instagram",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Tiktok"
                    ) {
                      vCardData.addURL(
                        "https://tiktok.com/@" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "tiktok",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Snapchat"
                    ) {
                      vCardData.addURL(
                        "https://snapchat.com/add/" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "snapchat",
                        contact[0].ContactCard[0][contactlinks[j]][i].value
                      );
                    } else if (
                      contact[0].ContactCard[0][contactlinks[j]][i].name
                        .charAt(0)
                        .toUpperCase() +
                        contact[0].ContactCard[0][contactlinks[j]][i].name
                          .slice(1)
                          .toLowerCase() ===
                      "Whatsapp"
                    ) {
                      vCardData.addSocial(
                        "whatsapp//send?phone=" +
                          contact[0].ContactCard[0][contactlinks[j]][i].value,
                        "whatsapp"
                      );
                    } 
                  }
                }
              }
            }
          }

          // Direct VCF DOwnloader Code
          const element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/vcard;charset=utf-8," +
              encodeURIComponent(vCardData.toString())
          );
          if (contact[0].name) {
            element.setAttribute("download", `${contact[0].name}.vcf`);
          } else {
            element.setAttribute("download", "Contact.vcf");
          }
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
    }
      
  }

  function detectOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return "iOS";
    } else if (/android/.test(userAgent)) {
      return "Android";
    } else {
      return "Unknown";
    }
  }

  useEffect(() => {
    // Disable text selection for elements
    // with class "no-select"

    const noSelectElements = document.querySelectorAll(".no-select");
    noSelectElements.forEach((element) => {
      element.style.webkitTouchCallout = "none";      
    });
  }, []);

  

  React.useEffect(() => {
    userData();
  }, []);

  return (
    <div
      className="no-select"
      onCopy={(e) => e.preventDefault()}
      onCopyCapture={(e) => e.preventDefault()}
      style={{
        // marginTop: "5%",
        height: "100vh",
        background: color
          ? `linear-gradient(to Bottom, ${color}, white, white)`
          : "white",
        // opacity: color ? 0.6 : 1,
      }}
    >
      <div
        style={{
          // background: color
          //   ? `linear-gradient(to top, ${color}, white)`
          //   : "white",
          position: "relative", // Ensuring relative positioning for absolute elements
        }}
      >
        <img
          onContextMenu={(e) => e.preventDefault()}
          src={
            cover
              ? membership === "normal"
                ? bannerImage
                : cover
              : bannerImage
          }
          alt="Banner"
          style={{
            width: "100%",
            height: 180,
            objectFit: "cover",
            maxWidth: "100%",
          }}
        />

        <div
          className="imagedivstyle"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {cropped && (
            <div
              style={{
                position: "absolute",
                // top: "50%",
                left: "50%",
                transform: "translate(-50%, 0%)",
              }}
            >
              <Picture
                source={cropped}
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "100px",
                  borderWidth: "4px",
                  borderColor: "white",
                  backgroundColor: "#F2F3F5",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  borderStyle: "solid",
                }}
              />
            </div>
          )}

          {!cropped && (
            <div
              style={{
                position: "absolute",
                // top: "50%",
                left: "50%",
                transform: "translate(-50%, 0%)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "150px",
                  height: "150px",
                  borderRadius: "75px",
                  borderWidth: "4px",
                  borderColor: "white",
                  backgroundColor: "#F2F3F5",
                  alignItems: "center",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={User}
                  alt="User"
                  style={{ width: "70px", height: "70px" }}
                />
              </div>
            </div>
          )}

          {logo ? (
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 75,
                borderWidth: 4,
                elevation: 4,
                backgroundColor: "white",
                overflow: "hidden",
                borderStyle: "solid",
                borderColor: logo ? "white" : "black",
                position: "absolute",
                // top: "50%",
                right: "25%",
                transform: "translate(162%, 153%)",
                zIndex: 1,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <img
                onContextMenu={(e) => e.preventDefault()}
                src={
                  membership === "normal"
                    ? require("../images/pop_it.png")
                    : logo
                }
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          ) : (
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 75,
                borderWidth: 4,
                elevation: 4,
                backgroundColor: "white",
                overflow: "hidden",
                borderStyle: "solid",
                borderColor: logo ? "white" : "white",
                position: "absolute",
                // top: "50%",
                right: "25%",
                transform: "translate(162%, 153%)",
                zIndex: 1,
              }}
            >
              <img
                onContextMenu={(e) => e.preventDefault()}
                src={require("../images/pop_it.png")}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
          )}
        </div>
      </div>

      <div
        style={
          {
            // background: color
            //   ? `linear-gradient(to bottom, ${color}, white)`
            //   : "white",
          }
        }
      >
        {job && company ? (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              paddingTop: "6%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15%",
                paddingLeft: membership !== "normal" ? "6%" : 0,
                width: "85%",
              }}
            >
              <p
                style={{
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                  // fontStyle: "normal",

                  fontWeight: 600,
                  fontSize: 24,
                  margin: 0,
                  // whiteSpace: "nowrap", // Prevents text from wrapping
                  // overflow: "hidden", // Hides any overflowing text
                  // textOverflow: "clip", // Adds ellipsis to indicate overflow
                  textAlign: "center",
                }}
              >
                {name}
              </p>
              {membership !== "normal" ? (
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={verify}
                  alt="Verify"
                  style={{
                    width: 20,
                    height: 20,
                    paddingTop: "0.5%",
                    marginLeft: "2%",
                  }}
                />
              ) : null}
            </div>

            <p
              style={{
                textAlign: "center",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 600,
                // fontStyle: "normal",
                marginTop: 1,
                marginBottom: 0,
                marginLeft: 5,
                marginRight: 5,
                fontSize: 17,
                // lineHeight: 1.43
              }}
            >
              {job} at {company}
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 400,
                fontStyle: "normal",
                marginTop: 4,
                marginBottom: 20,
                fontSize: 16,
              }}
            >
              {add}
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 400,
                fontStyle: "normal",
                marginTop: 1,
                marginBottom: 20,
                fontSize: 16,
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              {bio}
            </p>
          </div>
        ) : (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "22%",
                paddingLeft: membership !== "normal" ? "6%" : 0,
                width: "90%",
              }}
            >
              <p
                style={{
                  fontFamily:
                    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                  // fontStyle: "normal",

                  fontWeight: 600,
                  fontSize: 24,
                  margin: 0,
                }}
              >
                {name}
              </p>
              {membership !== "normal" ? (
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={verify}
                  alt="Verify"
                  style={{
                    width: 20,
                    height: 20,
                    paddingTop: "0.5%",
                    marginLeft: "2%",
                  }}
                />
              ) : null}
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 600,
                // fontStyle: "normal",
                marginTop: 1,
                marginBottom: 0,
                fontSize: 17,
                // lineHeight: 1.43
              }}
            >
              {job ? job : null} {job && company ? "at" : null}{" "}
              {company ? company : null}
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 400,
                // fontStyle: "normal",
                marginTop: 4,
                fontSize: 16,
              }}
            >
              {add}
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 400,
                fontStyle: "normal",
                marginTop: 1,
                fontSize: 16,
                marginLeft: 10,
                marginRight: 10,
                marginBottom: 20,
              }}
            >
              {bio}
            </p>
          </div>
        )}
      </div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          display: "flex",
          marginTop: "2%",
        }}
      >
        <button
          style={{
            backgroundColor:
              color === "" || color === "white" ? "black" : color,
            paddingLeft: "15%",
            paddingRight: "15%",
            borderRadius: 50,
            boxShadow:
              "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px ",
            paddingTop: 0,
            paddingBottom: 0,
          }}
          onClick={() => {
            param1 ? (param1 === "null" ? save() : connectMe()) : save();
          }}
          disabled={loading}
        >
          {loading ? (
            <div className="spinner"></div>
          ) : (
            <p
              style={{
                color: "white",
                fontWeight: "bold",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontSize: 19,
              }}
            >
              {param1
                ? param1 === "null"
                  ? "Save Contact"
                  : "Connect With Me"
                : "Save Contact"}
            </p>
          )}
        </button>
        {/* <FontAwesomeIcon
          style={{ marginLeft: "3%" }}
          icon={faUserPlus}
          size="2x"
          color={color === "" || color === "white" ? "black" : color}
        /> */}
      </div>
      <div
        style={{
          // backgroundColor: "white",
          margin: "8.09%",
          flexDirection: "row",
          justifyContent: "space-around",
          flexWrap: "wrap",
          alignItem: "center",
          display: "flex",
          boxSizing: "border-box",
        }}
      >
        {allData &&
          Array.isArray(allData) &&
          allData.length > 0 &&
          allData.map((item, index) =>
            item.check ? (
              item.highlight ? (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "2%",
                    width: "100%",
                    padding: "3%",
                    backgroundColor: "#F5F5F4",
                    borderRadius: 14,
                  }}
                >
                  <button
                    style={{
                      shadowColor: "rgba(0, 0, 0, 0.8)", // Shadow color
                      shadowOpacity: 0.6, // Shadow opacity
                      shadowOffset: { width: 2, height: 2 }, // Shadow offset
                      shadowRadius: 4, // Shadow radius
                      elevation: 4, // Android shadow elevation
                      borderRadius: 12,
                      marginBottom: 0,
                      backgroundColor: "transparent",
                      padding: 0,
                      paddingRight: "2%",
                    }}
                    onClick={() => {
                      if (item.name === "Numbers") {
                        openMessage("sms", item.value);
                        tap(number, item.value, "numbers");
                      } else if (item.name === "Whatsapp") {
                        window.open(
                          `whatsapp://send?phone=${item.value}`,
                          "_self"
                        );
                        tap(whatsapp, item.value, "whatsapp");
                      } else if (item.name === "Facetime") {
                        tap(facetime, item.value, "facetime");
                        window.open(`facetime://tel:${item.value}`, "_self");
                      } else if (item.name === "Wechat") {
                        tap(wechat, item.value, "wechat");
                        navigator.clipboard.writeText(item.value);
                      } else if (item.name === "Linktree") {
                        tap(linktree, item.value, "linktree");
                        window.open(item.value, "_self");
                      } else if (item.name === "Twitch") {
                        tap(twitch, item.value, "twitch");
                        window.open("https://twitch.tv/" + item.value, "_self");
                      } else if (item.name === "Zelle") {
                        tap(zelle, item.value, "zelle");
                        navigator.clipboard.writeText(item.value, "_self");
                      } else if (item.name === "Zillow") {
                        tap(zillow, item.value, "zillow");
                        window.open(item.value, "_self");
                      } else if (item.name === "Cash") {
                        tap(cash, item.value, "cash");
                        window.open("https://cash.app/$" + item.value, "_self");
                      } else if (item.name === "Venmo") {
                        tap(venmo, item.value, "venmo");
                        window.open("https://venmo.com/" + item.value, "_self");
                      } else if (item.name === "Square") {
                        tap(square, item.value, "square");
                        window.open(item.value, "_self");
                      } else if (item.name === "Twitter") {
                        tap(twitter, item.value, "twitter");
                        window.open(
                          "https://twitter.com/" + item.value,
                          "_self"
                        );
                      } else if (item.name === "Clubhouse") {
                        tap(clubhouse, item.value, "clubhouse");
                        window.open(
                          "https://clubhouse.com/@" + item.value,
                          "_self"
                        );
                      } else if (item.name === "Calendly") {
                        tap(calendly, item.value, "calendly");
                        window.open(item.value, "_self");
                      } else if (item.name === "Soundcloud") {
                        tap(soundcloud, item.value, "soundcloud");
                        window.open(item.value, "_self");
                      } else if (item.name === "Customlink") {
                        tap(customLink, item.value, "customLink");
                        window.open(item.value, "_self");
                      } else if (item.name === "Paypal") {
                        tap(paypal, item.value, "paypal");
                        window.open(item.value, "_self");
                      } else if (item.name === "Yelp") {
                        tap(yelp, item.value, "yelp");
                        window.open(item.value, "_self");
                      } else if (item.name === "Spotify") {
                        tap(spotify, item.value, "spotify");
                        window.open(item.value, "_self");
                      } else if (item.name === "Opensea") {
                        tap(opensea, item.value, "opensea");
                        window.open(item.value, "_self");
                      } else if (item.name === "Telegram") {
                        tap(telegram, item.value, "telegram");
                        window.open("https://t.me/" + item.value, "_self");
                      } else if (item.name === "Discord") {
                        tap(discord, item.value, "discord");
                        window.open(item.value, "_self");
                      } else if (item.name === "Applemusic") {
                        tap(applemusic, item.value, "applemusic");
                        window.open(item.value, "_self");
                      } else if (item.name === "Reviews") {
                        tap(reviews, item.value, "reviews");
                        window.open(item.value, "_self");
                      } else if (item.name === "Pinterest") {
                        tap(pinterest, item.value, "pinterest");
                        window.open(
                          "https://pinterest.com/" + item.value,
                          "_self"
                        );
                      } else if (item.name === "Hoobe") {
                        tap(hoobe, item.value, "hoobe");
                        window.open(item.value, "_self");
                      } else if (item.name === "Podcasts") {
                        tap(podcasts, item.value, "podcasts");
                        window.open(item.value, "_self");
                      } else if (item.name === "Poshmark") {
                        tap(poshmark, item.value, "poshmark");
                        window.open(item.value, "_self");
                      } else if (item.name === "Chili Piper") {
                        tap(chiliPiper, item.value, "chiliPiper");
                        window.open(item.value, "_self");
                      } else if (item.name === "Booksy") {
                        tap(booksy, item.value, "booksy");
                        window.open(item.value, "_self");
                      } else if (item.name === "Etsy") {
                        tap(etsy, item.value, "etsy");
                        window.open(item.value, "_self");
                      } else if (item.name === "Applink") {
                        tap(appLink, item.value, "appLink");
                        window.open(item.value, "_self");
                      } else if (item.name === "Facebook") {
                        tap(facebook, item.value, "facebook");
                        window.open(item.value, "_self");
                      } else if (item.name === "Linkedin") {
                        tap(linkedin, item.value, "linkedin");
                        window.open(item.value, "_self");
                      } else if (item.name === "Youtube") {
                        tap(youtube, item.value, "youtube");
                        window.open(item.value, "_self");
                      } else if (item.name === "Website") {
                        tap(website, item.value, "website");
                        window.open(item.value, "_self");
                      } else if (item.name === "Emails") {
                        tap(email, item.value, "emails");
                        openMessage("mailto", item.value);
                      } else if (item.name === "File") {
                        tap(file, item.value, "file");
                        window.open(item.value, "_self");
                      } else if (item.name === "Call") {
                        tap(call, item.value, "call");
                        openMessage("tel", item.value);
                      } else if (item.name === "Instagram") {
                        tap(instagram, item.value, "instagram");
                        window.open(
                          `https://www.instagram.com/${item.value}`,
                          "_self"
                        );
                      } else if (item.name === "Tiktok") {
                        tap(tiktok, item.value, "tiktok");
                        window.open(
                          "https://tiktok.com/@" + item.value,
                          "_self"
                        );
                      } else if (item.name === "Snapchat") {
                        tap(snapchat, item.value, "snapchat");
                        window.open(
                          "https://snapchat.com/add/" + item.value,
                          "_self"
                        );
                      } else if (item.name === "Addresses") {
                        tap(address, item.value, "addresses");
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${item.value}`,
                          "_self"
                        );
                      } else if (item.name === "Contact") {
                        contact_card();
                      } else if (item.name === "Trustpilot") {
                        tap(trustpilot, item.value, "trustpilot");
                        window.open(item.value, "_self");
                      }
                    }}
                  >
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={
                        item.image.includes("https")
                          ? item.image
                          : getImageSource(item.image)
                      }
                      alt="message"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: 20,
                        boxShadow: "0px 4px 5.84px rgba(128, 128, 128, 0.35)",
                        objectFit: "cover",
                      }}
                    />
                  </button>
                  <div style={{ marginHorizontal: "5%", width: "80%" }}>
                    <p
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily:
                          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                        marginBottom: 0,
                        marginTop: item.description ? "5%" : 0,
                      }}
                    >
                      {item.title === "Numbers"
                        ? "Number"
                        : item.title === "Applink"
                        ? "App Store"
                        : item.title === "Emails"
                        ? "Email"
                        : item.title === "File"
                        ? item.title
                        : item.title === "Addresses"
                        ? "Address"
                        : item.title
                        ? item.title
                        : item.name}
                    </p>

                    {item.description ? (
                      <p
                        style={{
                          color: "black",
                          fontSize: 12,
                          fontFamily:
                            "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                          fontWeight: 400,
                          marginTop: 4,
                          marginBottom: 4,
                          // textAlign: "justify",
                        }}
                      >
                        {item.description}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : item.name === "Expanded" ? (
                <button
                  key={index}
                  style={{
                    // backgroundColor: expandedIndex === index ? "white" : "grey",
                    width: "90%",
                    borderColor: "#EDEDEC",
                    // borderWidth: 1,
                    // borderStyle: "solid",
                    padding: 0,
                    margin: "4%",
                    height: expandedIndex === index ? "auto" : "",
                  }}
                  onClick={() => handleExpand(index)}
                >
                  <div>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "#EDEDEC",
                        paddingLeft: "5%",
                        paddingRight: "5%",
                      }}
                    >
                      <p
                        style={{
                          color: "black",
                          fontFamily:
                            "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                          fontWeight: "bold",
                          fontSize: 16,
                          // textAlign: "justify",
                          wordWrap: "break-word",
                        }}
                      >
                        {item.headerText}
                      </p>
                      <FontAwesomeIcon
                        icon={
                          expandedIndex === index ? faChevronUp : faChevronDown
                        }
                        size="1x"
                        color="black"
                      />
                    </div>
                    {expandedIndex === index && (
                      <div
                        style={{
                          marginVertical: "2%",
                          display: "flex",
                          // justifyContent: "flex-start",
                          // alignItems: "center",
                          backgroundColor: "white",
                          paddingLeft: "5%",
                          paddingRight: "5%",
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontFamily:
                              "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                            // textAlign: "justify",
                            wordWrap: "break-word",
                            fontSize: 14,
                          }}
                        >
                          {item.bodyText}
                        </p>
                      </div>
                    )}
                  </div>
                </button>
              ) : item.name === "Embedded" ? (
                <div
                  style={{
                    borderRadius: 20,
                    overflow: "hidden",
                    width: "100%",
                    height: 200,
                  }}
                >
                  <ReactPlayer
                    width={"100%"}
                    height={"100%"}
                    url={`https://www.youtube.com/watch?v=${item.value}`}
                  />
                </div>
              ) : item.name === "Header" ? (
                <div
                  key={index}
                  style={{
                    marginBottom: "2%",
                    marginLeft: "10%",
                    marginRight: "10%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <p
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily:
                        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                      // textAlign: "justify",
                    }}
                  >
                    {item.value}
                  </p>
                </div>
              ) : (
                <div
                  key={index}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "2%",
                  }}
                >
                  <button
                    style={{
                      shadowColor: "rgba(0, 0, 0, 0.8)", // Shadow color
                      shadowOpacity: 0.6, // Shadow opacity
                      shadowOffset: { width: 2, height: 2 }, // Shadow offset
                      shadowRadius: 4, // Shadow radius
                      elevation: 4, // Android shadow elevation
                      borderRadius: 12,
                      marginBottom: 0,
                      backgroundColor: "transparent",
                      paddingBottom: 0,
                    }}
                    onClick={() => {
                      // console.log("itemname: ", item.title);
                      if (item.name === "Numbers") {
                        openMessage("sms", item.value);
                        tap(number, item.value, "numbers");
                      } else if (item.name === "Whatsapp") {
                        window.open(
                          `whatsapp://send?phone=${item.value}`,
                          "_self"
                        );
                        tap(whatsapp, item.value, "whatsapp");
                      } else if (item.name === "Facetime") {
                        tap(facetime, item.value, "facetime");
                        window.open(`facetime://tel:${item.value}`, "_self");
                      } else if (item.name === "Wechat") {
                        tap(wechat, item.value, "wechat");
                        navigator.clipboard.writeText(item.value);
                      } else if (item.name === "Linktree") {
                        tap(linktree, item.value, "linktree");
                        window.open(item.value, "_self");
                      } else if (item.name === "Twitch") {
                        tap(twitch, item.value, "twitch");
                        window.open("https://twitch.tv/" + item.value, "_self");
                      } else if (item.name === "Zelle") {
                        tap(zelle, item.value, "zelle");
                        navigator.clipboard.writeText(item.value, "_self");
                      } else if (item.name === "Zillow") {
                        tap(zillow, item.value, "zillow");
                        window.open(item.value, "_self");
                      } else if (item.name === "Cash") {
                        tap(cash, item.value, "cash");
                        window.open("https://cash.app/$" + item.value, "_self");
                      } else if (item.name === "Venmo") {
                        tap(venmo, item.value, "venmo");
                        window.open("https://venmo.com/" + item.value, "_self");
                      } else if (item.name === "Square") {
                        tap(square, item.value, "square");
                        window.open(item.value, "_self");
                      } else if (item.name === "Twitter") {
                        tap(twitter, item.value, "twitter");
                        window.open(
                          "https://twitter.com/" + item.value,
                          "_self"
                        );
                      } else if (item.name === "Clubhouse") {
                        window.open(
                          "https://clubhouse.com/@" + item.value,
                          "_self"
                        );
                        tap(clubhouse, item.value, "clubhouse");
                      } else if (item.name === "Calendly") {
                        tap(calendly, item.value, "calendly");
                        window.open(item.value, "_self");
                      } else if (item.name === "Soundcloud") {
                        tap(soundcloud, item.value, "soundcloud");
                        window.open(item.value, "_self");
                      } else if (item.name === "Customlink") {
                        tap(customLink, item.value, "customLink");
                        window.open(item.value, "_self");
                      } else if (item.name === "Paypal") {
                        tap(paypal, item.value, "paypal");
                        window.open(item.value, "_self");
                      } else if (item.name === "Yelp") {
                        tap(yelp, item.value, "yelp");
                        window.open(item.value, "_self");
                      } else if (item.name === "Spotify") {
                        tap(spotify, item.value, "spotify");
                        window.open(item.value, "_self");
                      } else if (item.name === "Opensea") {
                        tap(opensea, item.value, "opensea");
                        window.open(item.value, "_self");
                      } else if (item.name === "Telegram") {
                        tap(telegram, item.value, "telegram");
                        window.open("https://t.me/" + item.value, "_self");
                      } else if (item.name === "Discord") {
                        tap(discord, item.value, "discord");
                        window.open(item.value, "_self");
                      } else if (item.name === "Applemusic") {
                        tap(applemusic, item.value, "applemusic");
                        window.open(item.value, "_self");
                      } else if (item.name === "Reviews") {
                        tap(reviews, item.value, "reviews");
                        window.open(item.value, "_self");
                      } else if (item.name === "Pinterest") {
                        tap(pinterest, item.value, "pinterest");
                        window.open(
                          "https://pinterest.com/" + item.value,
                          "_self"
                        );
                      } else if (item.name === "Hoobe") {
                        tap(hoobe, item.value, "hoobe");
                        window.open(item.value, "_self");
                      } else if (item.name === "Podcasts") {
                        tap(podcasts, item.value, "podcasts");
                        window.open(item.value, "_self");
                      } else if (item.name === "Poshmark") {
                        tap(poshmark, item.value, "poshmark");
                        window.open(item.value, "_self");
                      } else if (item.name === "Chili Piper") {
                        tap(chiliPiper, item.value, "chiliPiper");
                        window.open(item.value, "_self");
                      } else if (item.name === "Booksy") {
                        tap(booksy, item.value, "booksy");
                        window.open(item.value, "_self");
                      } else if (item.name === "Etsy") {
                        tap(etsy, item.value, "etsy");
                        window.open(item.value, "_self");
                      } else if (item.name === "Applink") {
                        tap(appLink, item.value, "appLink");
                        window.open(item.value, "_self");
                      } else if (item.name === "Facebook") {
                        tap(facebook, item.value, "facebook");
                        window.open(item.value, "_self");
                      } else if (item.name === "Linkedin") {
                        tap(linkedin, item.value, "linkedin");
                        window.open(item.value, "_self");
                      } else if (item.name === "Youtube") {
                        tap(youtube, item.value, "youtube");
                        window.open(item.value, "_self");
                      } else if (item.name === "Website") {
                        tap(website, item.value, "website");
                        window.open(item.value, "_self");
                      } else if (item.name === "Emails") {
                        tap(email, item.value, "emails");
                        openMessage("mailto", item.value);
                      } else if (item.name === "File") {
                        tap(file, item.value, "file");
                        window.open(item.value, "_self");
                      } else if (item.name === "Call") {
                        tap(call, item.value, "call");
                        openMessage("tel", item.value);
                      } else if (item.name === "Instagram") {
                        tap(instagram, item.value, "instagram");
                        window.open(
                          `https://www.instagram.com/${item.value}`,
                          "_self"
                        );
                      } else if (item.name === "Tiktok") {
                        tap(tiktok, item.value, "tiktok");
                        window.open(
                          "https://tiktok.com/@" + item.value,
                          "_self"
                        );
                      } else if (item.name === "Snapchat") {
                        window.open(
                          "https://snapchat.com/add/" + item.value,
                          "_self"
                        );
                        tap(snapchat, item.value, "snapchat");
                      } else if (item.name === "Addresses") {
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${item.value}`,
                          "_self"
                        );
                        tap(address, item.value, "addresses");
                      } else if (item.name === "Contact") {
                        contact_card();
                      } else if (item.name === "Trustpilot") {
                        window.open(item.value, "_self");
                        tap(trustpilot, item.value, "trustpilot");
                      }
                    }}
                  >
                    <img
                      onContextMenu={(e) => e.preventDefault()}
                      src={
                        item.image.includes("https")
                          ? item.image
                          : getImageSource(item.image)
                      }
                      alt="message"
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: 20,
                        boxShadow: "0px 4px 5.84px rgba(128, 128, 128, 0.35)",
                        objectFit: "cover",
                      }}
                    />
                  </button>
                  <p
                    style={{
                      marginTop: 5,
                      color: "black",
                      textAlign: "center",
                      fontSize: 11,
                      fontFamily:
                        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: 400,
                      fontStyle: "normal",
                    }}
                  >
                    {item.title === "Numbers"
                      ? "Number"
                      : item.title === "Applink"
                      ? "App Store"
                      : item.title === "Emails"
                      ? "Email"
                      : item.title === "File"
                      ? truncateTextAfterWords(item.title, 15)
                      : item.title === "Addresses"
                      ? "Address"
                      : item.title
                      ? truncateTextAfterWords(item.title, 15)
                      : item.name}
                  </p>
                </div>
              )
            ) : null
          )}
      </div>
      <div
        style={{
          height: 200,
          background: color
            ? `linear-gradient(to top, ${color}, white)`
            : "white",
          opacity: color ? 0.4 : 1,
        }}
      ></div>
    </div>
  );
}

export default Preview;
