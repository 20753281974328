import React, {useEffect, useState} from "react";
import '../components/Firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import {useParams} from "react-router-dom"
import Preview from "./Preview";
import { useSearchParams } from 'react-router-dom';


export default function Re() {

    const [searchParams] = useSearchParams();
    const param1 = searchParams.get('q')

    const [userLink, setUserLink] = useState("")

    const fetchUserData = () => {
        var uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState({}, document.title, clean_uri);
        }

        firebase.database()
        .ref('users/' + param1) 
        .once('value')
        .then((snapshot) => {
            // Data found based on the email field
            const userData = snapshot.val();
            setUserLink(userData.main_card[0].qr_link)
            //window.location.replace(userData.link + `?q=${param1}`)
            
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });
    };

    useEffect(() => {
        fetchUserData()
    }, [])

    return (
        <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
            {userLink ? (
                <iframe
                    src={userLink}
                    style={{ width: "100%", height: "100%", border: "none" }}
                    title="User Profile"
                ></iframe>
            ) : null}
        </div>
    );
    
}